.App {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.App-header {
  text-align: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0056b3;
}

form {
  display: grid;
  gap: 10px;
  margin: 20px 0;
}

.main-fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.skladka-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

h2 {
  width: 100%;
  margin-top: 20px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

label {
  display: block;
  margin-bottom: 5px;
}

.TextCopyArea {
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
}
